header.topNav2 {
  font-size: 1rem;
  background-color: rgba(25, 124, 145, 0.87);
  backdrop-filter: blur(10px);
  height: 80px;
  @media screen and (min-width: 576px) {
    height: 140px;
  }
}
.topNav2_con {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    max-width: 1000px;
  }
}
.topNav__logo2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topNav__logo2 img {
  width: 160px;
  height: auto;
  @media screen and (max-width: 576px) {
    width: 100px;
  }
}
header.topNav2 .hamburger {
  top: 50%;
  transform: translate(-10px, -50%);
}
.top_booknow {
  background: white;
  color: #13607b;
  padding: 13px 20px;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 1px;
  &:focus,
  &:hover {
    color: white;
    background: #95d6d6;
    text-decoration: none;
  }
}
.horizontal_menu {
  height: 100%;
  position: relative;
}
.horizontal_menu .horizontal_menu_ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  height: 100%;
  width: 92%;
  @media screen and (min-width: 1200px) {
    width: 900px;
    padding-left: 0;
  }
  @media screen and (min-width: 1600px) {
    width: 900px;
  }
  @media screen and (min-width: 1920px) {
    width: 900px;
  }
}
.horizontal_menu_ul li {
  list-style: none;
}
.horizontal_subMenu li {
  font-weight: 400;
}
.horizontal_menu_link a,
.horizontal_menu_link span,
header.topNav_desktop .navBar__subItem a {
  color: #fff;
  text-decoration: none;
  @media screen and (min-width: 1600px) {
    letter-spacing: 1px;
  }
  @media screen and (min-width: 2500px) {
    font-size: 18px;
  }
  // @media screen and (max-width: 1366px) {
  //   font-size: 0.75rem;
  // }
  // @media screen and (max-width: 1024px) {
  //   font-size: 0.6rem;
  // }
}
header.topNav_desktop .navBar__subItem a {
  color: #212934;
  font-size: 14px;
}
.horizontal_menu_link a::after,
.horizontal_menu_link span::after {
  content: " ";
  position: absolute;
  top: calc(50% + 1.15em);
  left: 0;
  width: 100%;
  border-top: 2px solid #95d6d6;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}

.horizontal_menu_link a:hover::after,
.horizontal_menu_link span:hover::after {
  transform: scaleX(1);
}

.horizontal_menu_link a:hover {
  color: #95d6d6;
}
.navBar__subItem2:hover {
  background-color: rgba(149, 214, 214, 0.83);
}
.horizontal_menu_item {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.horizontal_menu_item:hover .horizontal_subMenu {
  height: auto;
  background-color: rgba(255, 255, 255, 0.87);
  visibility: visible;
  opacity: 1;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.06);
}
.horizontal_subMenu {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  // padding: 25px 0;
  min-width: 200px;
  max-width: 302px;
  width: max-content;
  text-align: left;
  border-top: none;
  transition: all 0.3s ease-in-out;
  z-index: 4;
  padding: 0;
  @media screen and (max-width: 1024px) {
    padding: 15px 0;
    min-width: 70px;
    max-width: 125px;
  }
  @media screen and (max-width: 900px) {
    padding: 13px 0;
    min-width: 70px;
    max-width: 100px;
  }
}
.navBar__subItem {
  padding: 12px 20px;
  line-height: 1.5;
}
.horizontal_subMenu:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  background: #95d6d6;
  width: 100%;
  height: 2px;
  transform-origin: left center;
  transition: transform 0.6s;
}
.horizontal_menu_item:hover .horizontal_subMenu:before {
  transform: scaleX(1);
  transition: transform 0.6s;
}
.horizontal_menu_item:hover .horizontal_menu_link a {
  color: #95d6d6;
  transition: transform 0.3s;
}
.topNav_mobile .navBar__subLink {
  padding-left: 2.5rem;
}

// search bar
.search_container {
  position: absolute;
  top: 0;
  right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    right: 5rem;
  }
  @media screen and (min-width: 1600px) {
    right: 8rem;
  }
  @media screen and (min-width: 1920px) {
    right: 18rem;
  }
  @media screen and (min-width: 2500px) {
    right: 28rem;
  }
}
.search_container svg path {
  stroke: #fff;
}
.search_container:hover svg path {
  stroke: #95d6d6;
}
.searchBar3 {
  width: 100%;
  height: auto;
  transform: translate(-100%);
  transition: all 0.3s ease-in-out;
  background: #222;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  gap: 1rem;
  overflow: hidden;
}
.search_container_open + .searchBar3 {
  transform: translate(0);
  transition: all 0.3s ease-in-out;
}
.searchBar3 > form {
  width: 55%;
  margin: 3.5rem 0;
  @media screen and (min-width: 1200px) {
    width: 60%;
  }
  @media screen and (min-width: 1600px) {
    width: 60%;
  }
  @media screen and (min-width: 1920px) {
    width: 51%;
  }
  @media screen and (min-width: 2500px) {
    width: 48%;
  }
}
.searchBar3 svg {
  color: #fff;
}
.searchBar3 svg:hover {
  color: #c20f2f;
}
