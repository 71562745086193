$top-bar-z-index: 1000 !default;

.topNav {
  position: fixed;
  background: #222;
  z-index: $top-bar-z-index;
  height: map-get($top-bar-height, mobile);
  top: 0;
  width: 100%;
  font-size: 0.75rem;
  @include media-breakpoint-up(lg) {
    height: map-get($top-bar-height, desktop);
  }
}

a.topNav__back {
  position: absolute;
  left: 1rem;
  line-height: map-get($top-bar-height, mobile);
  color: white;
  &:hover {
    color: white;
    text-decoration: none;
  }
  @include media-breakpoint-up(lg) {
    line-height: map-get($top-bar-height, desktop);
  }
}

.topNav__siteSwitch {
  position: absolute;
  right: 1.5rem;
  line-height: map-get($top-bar-height, mobile);
  @include media-breakpoint-up(lg) {
    right: 2rem;
    line-height: map-get($top-bar-height, desktop);
  }
}

.topNav__men {
  color: white;
  letter-spacing: 2px;
  &:hover {
    color: white;
    text-decoration: none;
  }
}

.topNav__women {
  color: $primary-color;
  font-weight: bold;
  letter-spacing: 2px;
}

.topNav__sep {
  display: inline-block;
  padding: 0 1rem;
  color: white;
}
