.main-content {
  // padding-top: map-get($top-bar-height, mobile);

  // @include media-breakpoint-up(lg) {
  //   padding-top: map-get($top-bar-height, desktop);
  // }
}

.actions {
  margin: 1rem 0;
  // text-align: center;
  border-radius: 0;
  button {
    color: white;
    background: $link-color;
    border: 1px solid $link-color;
    padding: 11px 23px;
    border-radius: 4px;
    font-weight: $weight-bold;
    display: inline-block;
    margin-bottom: 0.2rem;
    &:focus,
    &:hover {
      background: $link-hover-color;
      color: #fff;
      border: 1px solid $link-hover-color;
      text-decoration: none;
    }
  }
}
.custom-select {
  position: relative;
}
.custom-select select {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}
.custom-select::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 16px;
  color: #333;
}
