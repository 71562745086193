$breakpoints: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 3,
);
$margin: 20px;

.blogTiles {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
  &.useSlider {
    display: block;
    .blogTile {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }
  &__loadMore {
    margin-bottom: 2rem;
    text-align: center;
    .thinBtn {
      color: white;
      background: #13607b;
      border: 1px solid #13607b;
      padding: 11px 23px;
      border-radius: 4px;
      font-weight: 700;
      margin-bottom: 0.2rem;
      cursor: pointer;
      &:hover,
      &:focus {
        background: transparent;
        color: #13607b;
        border: 1px solid #13607b;
        text-decoration: none;
      }
    }
  }
  &--productView .blogTile {
    @include media-breakpoint-up(xxl) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}

.blogTiles.useSlider .slick-next:before,
.blogTiles.useSlider .slick-prev:before {
  color: #000;
}

.blogTiles.useSlider .slick-prev {
  left: -12px;
  @media screen and (min-width: 576px) {
    left: -24px;
  }
}
.blogTiles.useSlider .slick-next {
  right: -12px;
  @media screen and (min-width: 576px) {
    right: -24px;
  }
}

.blogTile {
  padding: 10px;
  @media screen and (min-width: 576px) {
    padding: 20px;
  }
  @each $bp, $number in $breakpoints {
    @include media-breakpoint-up(#{$bp}) {
      flex: 0 0 100% / $number;
      max-width: 100% / $number;
    }
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 0.7rem;
  }
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    // background: #fff;
    color: $font-color;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    line-height: 1.1;
    text-align: left;
    overflow: hidden;
    &:hover,
    &:focus {
      color: $font-color;
      text-decoration: none;
      margin-top: -3px;
      box-shadow: 0 0 1px 1px rgba(190, 190, 190, 0.3);
      .blogTile__content {
        background: #fbfbfb;
      }
      .blogTile__date {
        background-color: #81c040;
      }
      .blogTile__btn {
        color: #056937;
      }
      .blogTile__btn-arrow {
        background-image: url(/assets/arrow-right-hover.svg);
        transition: transform 0.3s ease;
        transform: translateX(5px);
      }
    }
  }

  &__bg {
    height: 235px;
    padding-top: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.2s;
    @media screen and (max-width: 576px) {
      height: 230px;
    }
  }

  &__content {
    width: 100%;
    position: relative;
    overflow: hidden;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    padding: 0.5rem 1.2rem 1.5rem;
    text-align: left;
    background-color: #fff;
    min-height: 250px;
    @media screen and (max-width: 576px) {
      max-height: 272px;
      min-height: 150px;
    }
  }

  &__title {
    font-family: $family-header;
    font-size: 1.5rem;
    color: #056937;
    font-weight: 600;
    margin: 0.8rem 0 1rem;
    transition: all 0.2s;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-height: 1.2;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 576px) {
      // font-size: 1.2rem;
      // margin-top: 0;
    }
  }

  &__desc {
    line-height: 1.3;
    font-size: 0.95rem;
    color: #000;
    font-weight: 5s00;
    min-height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    transition: all 0.2s;
    @media screen and (max-width: 576px) {
      min-height: 60px;
      line-height: 29px;
      font-size: 1rem;
    }
  }
}
// .blogTile:nth-child(3n + 1) {
//   @media screen and (min-width: 576px) {
//     padding-left: 10px;
//   }
// }
// .blogTile:nth-child(3n) {
//   @media screen and (min-width: 576px) {
//     padding-right: 10px;
//   }
// }

.thinBtn {
  background-color: #fff;
  border: 1px solid $font-color;
  border-radius: 0;
  font-size: 0.8rem;
  letter-spacing: 1px;
  transition: all 0.15s;
  margin: auto;
  width: 10em;
  padding: 0.5rem 1rem;
  text-align: center;
  margin-top: 2rem;
}

.blogTile__btn {
  color: #056937;
  margin-top: 1.2rem;
  display: inline-flex;
  align-items: center;
  gap: 0.6rem;
  font-weight: 600;
}
.blogTile__btn-arrow {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url(/assets/arrow-right.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s ease;
}
.blogTile__date {
  position: absolute;
  background: #056937;
  width: 4.1rem;
  padding: 10px 5px;
  top: 0;
  right: 10px;
  color: #fff;
  font-weight: 800;
  font-size: 1.3rem;
  text-align: center;
}
.blogTile__day {
  font-size: 1.8rem;
}
