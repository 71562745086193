@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
@import "./layout/hamburger";
@import "./layout/top";
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";
@import "./modules/horizontalMenu";
@import "./modules/rotatingReviewPanel";

// components
@import "../components";

@import "./site/index";

//wave
@import "./modules/wave/wave";

//aos
@import "~aos/src/sass/aos";

@import "./youtubeNew.scss";
@import "./photoSlide.scss";

@media print {
  @import "./print";
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text {
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.photo_gallery_con .photo_gallery_item {
  flex-basis: 33.3%;
  max-width: 33.3%;
}

#footer-container > .container {
  @media screen and (min-width: 576px) {
    max-width: 80%;
    width: 80%;
  }
}
#footer.multiColumnPanel {
  @media screen and (min-width: 576px) {
    max-width: 100%;
    width: 100%;
  }
}
#footer.multiColumnPanel .container {
  @media screen and (min-width: 576px) {
    max-width: 100%;
    width: 100%;
    margin: auto;
  }
}
// #footer.multiColumnPanel img {
//   @media screen and (max-width: 576px) {
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
//   }
// }
.bg_dark_linear {
  width: 100%;
  height: 25%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}

.sliderAwardPanel img {
  width: 70%;
}
