.contentPanel {
  // background: white;
  padding: 1rem 0;
  position: relative;
  word-break: break-word;
  background-size: 100%;
  background-repeat: repeat;
  background-position: center;

  > * {
    // z-index: 1;
    position: relative;
  }

  h2 a.button {
    font-size: 2.6rem;
  }

  h3 a.button {
    font-size: 2.2rem;
  }

  h4 a.button {
    font-size: 1.2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }

  a.button {
    color: white;
    background: #056937;
    padding: 11px 23px;
    border-radius: 4px;
    font-weight: $weight-bold;
    display: inline-block;
    margin-bottom: 0.2rem;
    &:focus,
    &:hover {
      background: #81c040;
      color: #fff;
      text-decoration: none;
    }
  }
  a.button.secondary {
    color: white;
    background: #81c040;
    padding: 11px 23px;
    border-radius: 4px;
    font-weight: $weight-bold;
    display: inline-block;
    margin-bottom: 0.2rem;
    &:focus,
    &:hover {
      color: white;
      background: #056937;
      text-decoration: none;
    }
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}

//override bootstrap container size
.container {
  @include media-breakpoint-up(xl) {
    max-width: 1300px;
  }
}
