$top-bar-z-index: 1000 !default;
$top-bar-height: (mobile: 2rem, desktop: 2rem) !default;

.topNav__logo {
  @extend %topNavMarginTop;
  position: fixed;
  top: map-get($top-bar-height, mobile);
  z-index: $top-bar-z-index + 2;

  img {
    width: 80px;
  }

  @include media-breakpoint-up(lg) {
    top: map-get($top-bar-height, desktop);
    left: 4rem;

    img {
      width: 160px;
    }
  }

}
