// overriding bootstrap variables in _boostrap-override.scss
@import url("https://use.typekit.net/paa8xyo.css");

$family-base: "open-sans", sans-serif;
$family-header: "montserrat", sans-serif;

// font-size
$font-size-desktop: 17px;
$font-size-mobile: 15px;

$weight-thin: 100;
$weight-extralight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-normal: $weight-regular;
$weight-semibold: 600;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #13607b;
$color-grey: #4a4e57;
$color-lightgrey: #4d515a;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #056937;

// more colors
$color-gold: #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-green;
$font-color: $color-grey;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: #056937;
$link-hover-color: #81c040;

// top-bar
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
$top-bar-height: (
  mobile: 44px,
  small-device: 81px,
  tablet: 81px,
  desktop: 81px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
