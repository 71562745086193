b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;
  &:hover,
  &:focus,
  &:active {
    color: $link-hover-color;
    text-decoration: none;
  }
}

// font family
h1,
h2,
h3,
h4 {
  font-family: $family-header;
}
h5,
h6 {
  font-family: $family-base;
}

// font weight
h1,
h2 {
  font-weight: $weight-extralight;
}
h3,
h4,
h5,
h6 {
  font-weight: $weight-regular;
}
h1 strong,
h2 strong,
h3 strong,
h4 strong {
  font-weight: $weight-medium;
}
h5 strong,
h6 strong {
  font-weight: $weight-semibold;
}

// color
h1,
h2,
h3 {
  color: $color-green;
}
h4 {
  color: $color-lightgrey;
}
h5,
h6 {
  color: $color-grey;
}

// size
h1 {
  font-size: rem(36, $font-size-mobile);
}
h2 {
  font-size: rem(28, $font-size-mobile);
}
h3 {
  font-size: rem(24, $font-size-mobile);
}
h4 {
  font-size: rem(20, $font-size-mobile);
}
h5 {
  font-size: rem(14, $font-size-mobile);
}
h6 {
  font-size: rem(10, $font-size-mobile);
}
@include media-breakpoint-up(lg) {
  h1 {
    font-size: rem(48, $font-size-desktop);
  }
  h2 {
    font-size: rem(48, $font-size-desktop);
  }
  h3 {
    font-size: rem(28, $font-size-desktop);
  }
  h4 {
    font-size: rem(24, $font-size-desktop);
  }
  h5 {
    font-size: rem(21, $font-size-desktop);
  }
  h6 {
    font-size: rem(15, $font-size-desktop);
  }
}

// html editor
.text-tiny {
  font-size: 0.75rem;
}
.text-big {
  font-size: 1.5rem;
}
p {
  margin-bottom: 0;
  font-family: $family-base;
  line-height: 29px;
}
p strong {
  font-weight: $weight-bold;
}
body {
  font-family: $family-base;
}
