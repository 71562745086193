.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {
  &:hover,
  &:focus {
    text-decoration: none;
    // .pageTile_details:before {
    //   background: $primary-color;
    // }
    .pageTile_details {
      background: $color-orange;
    }

    .pageTile__title,
    .pageTile_desc {
      color: white;
    }

    .tile__bg {
      transform: scale(1.02);
    }
  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  margin: 0.5rem 0;
  color: black;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.9rem;
  color: white;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 0.5rem 0.8rem;
  text-align: left;
  background-color: $primary-color;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(
      to bottom left,
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

// big page tile
.pageTile__bsyc__content:hover .tile_btn_animation::after {
  visibility: visible;
  transform: scale(100) translateX(2px);
}
.c-button {
  color: #13607b;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  border: 0;
  background: transparent;
  padding: 0.6rem 1rem;
}
.c-button--gooey {
  position: relative;
  transition: all 700ms ease;
}
.c-button--gooey .c-button__blobs {
  height: 100%;
  filter: url(#goo);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: -3px;
  right: -1px;
  z-index: -1;
}
.c-button--gooey .c-button__blobs div {
  background-color: #13607b;
  width: 34%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  transform: scale(1.4) translateY(125%) translateZ(0);
  transition: all 700ms ease;
  @media screen and (max-width: 576px) {
    width: 50%;
  }
}
.c-button--gooey .c-button__blobs div:nth-child(1) {
  left: -5%;
}
.c-button--gooey .c-button__blobs div:nth-child(2) {
  left: 30%;
  transition-delay: 60ms;
}
.c-button--gooey .c-button__blobs div:nth-child(3) {
  left: 66%;
  transition-delay: 25ms;
}
.c-button--gooey:hover {
  color: #fff;
}
.c-button--gooey:hover .c-button__blobs div {
  transform: scale(1.4) translateY(0) translateZ(0);
}

// small page tile
.pageTile__bsyc__button {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  font-size: 14px;
  margin-top: 1.2rem;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
  &:focus,
  &:hover {
    color: #13607b;
    background: #fff;
    border: 1px solid #fff;
    text-decoration: none;
  }
}
